/**=====================
    49. Badge CSS start
==========================**/
.badge {
  padding: $badge-padding;

  &+.badge {
    margin-left: 5px;
  }

  svg {
    width: $badge-svg-size;
    height: $badge-svg-size;
    padding-top: 3px;
  }
}

// flat badge loop css
@each $pill-badge-name,
$pill-badge-color in (primary, var(--theme-deafult)),
(secondary, $secondary-color),
(success, $success-color),
(danger, $danger-color),
(info, $info-color),
(light, $light-color),
(dark, $dark-color),
(warning, $warning-color) {
  .pill-badge-#{$pill-badge-name} {
    background-color: $pill-badge-color;
    box-shadow: -0.5px 4.33px 16px 0px rgba($pill-badge-color, 0.25);
    color: $white;
    border-radius: 0;
  }
}

.pill-badge-primary {
  background-color: var(--theme-deafult);
}

.pill-badge-secondary {
  background-color: var(--theme-secondary);
}



// round badge loop css
@each $round-badge-name,
$round-badge-color in (primary, var(--theme-deafult)),
(secondary, $secondary-color),
(success, $success-color),
(danger, $danger-color),
(info, $info-color),
(light, $light-color),
(dark, $dark-color),
(warning, $warning-color) {
  .round-badge-#{$round-badge-name} {
    border-radius: 6px;
    background-color: $round-badge-color;
    box-shadow: -0.5px -6.67px 14px 0px rgba($round-badge-color, 0.18);
    color: $white !important;
  }
}

.round-badge-primary {
  background-color: var(--theme-deafult);
}

.round-badge-secondary {
  background-color: var(--theme-secondary);
}

/**=====================
    49. Badge CSS Ends
==========================**/

.badge{
  &.badge-outline-success{
    color: $success-color;
    border: 1px solid $success-color;
  }  
  &.badge-outline-danger{
    color: $danger-color;
    border: 1px solid $danger-color;
  }  
  &.badge-purple{
      color: #fff;
      background-color: $purple-color;  
  }
  &.badge-outline-purple{
    color: $purple-color;
    border: 1px solid $purple-color;
  }
  &.badge-pink{
      color: #fff;
      background-color: $pink-color;  
  }
  &.badge-outline-pink{
    color: $pink-color;
    border: 1px solid $pink-color;
  } 
  &.badge-yellow{
      background-color: $yellow-color;  
  }
  &.badge-outline-yellow{
    color: $yellow-color;
    border: 1px solid $yellow-color;
  } 
  &.badge-sky{
      background-color: $sky-color;  
  }
  &.badge-outline-sky{
    color: $sky-color;
    border: 1px solid $sky-color;
  } 
  &.badge-orange{
      color: #fff;
      background-color: $orange-color;  
  }
  &.badge-outline-orange{
    color: $orange-color;
    border: 1px solid $orange-color;
  } 
  &.badge-blue{
      background-color: $blue-color;        
  }
  &.badge-outline-blue{
    color: $blue-color;
    border: 1px solid $blue-color;
  } 
  &.badge-azure{
      background-color: $azure-color;
      color: #fff;
  }
  &.badge-outline-azure{
    color: $azure-color;
    border: 1px solid $azure-color;
  } 
  &.badge-green{
      background-color: $green-color;
      color: #fff;
  }
  &.badge-outline-green{
    color: $green-color;
    border: 1px solid $green-color;
  } 
  &.badge-magenta{
      background-color: $magenta-color;
      color: #fff;
  }
  &.badge-outline-magenta{
    color: $magenta-color;
    border: 1px solid $magenta-color;
  } 
  &.badge-maroon{
      background-color: $maroon-color;
      color: #fff;
  }
  &.badge-outline-maroon{
    color: $maroon-color;
    border: 1px solid $maroon-color;
  } 
}