/**=====================
     16. Popover CSS Start
==========================**/
.popover-header {
  background-color: $dark-color;
  color: $white;
}
.popover-body {
  color: $theme-body-font-color;
}
.popover-main{
  .btn-showcase{
    a{
      color: $white;
    }
    button{
      &:last-child{
        margin-right: 0;
      }
    }
  }
}
/**=====================
     16. Popover CSS Ends
==========================**/

.popover {
  z-index: 100000;
}
.popover-header {
  background-color: $light-color;
  color: $dark-color;
  font-size: .90rem;
  padding: 0.35rem 0.55rem;
}
.popover-body {
  padding: 0.5rem 0.5rem;
  p {
      margin-bottom: .5rem;
      letter-spacing: 0px;
  }
}