/**=====================
     21. Select 2 CSS Start
==========================**/
.ng-select {
  .ng-select-container,
  .ng-dropdown-panel {
    border: 1px solid $light-semi-gray !important;
  }
  .ng-select-container {
    background-color: $transparent-color;
  }
  &.rtl {
    .ng-select-container {
      .ng-value-container {
        padding-right: 10px;
      }
    }
  }
  &.btn-pill {
    .ng-select-container {
      border-radius: 60px;
    }
  }
  &.btn-square {
    .ng-select-container {
      border-radius: 0;
    }
  }
  &.input-air-primary {
    .ng-select-container {
      box-shadow: 0 3px 5px 1px rgba(88, 103, 221, 0.15) !important;
    }
  }
  &.ng-select-opened {
    &.btn-pill {
      > .ng-select-container {
        border-radius: 18px;
      }
    }
    > .ng-select-container {
      border-color: $light-semi-gray !important;
      background-color: $transparent-color;
    }
  }
  &.ng-select-multiple {
    .ng-select-container {
      .ng-value-container {
        .ng-placeholder {
          top: 7px;
        }
        .ng-value {
          background-color: $primary-color !important;
          color: $white;
          .ng-value-icon {
            &:hover {
              background-color: darken($primary-color, 5%) !important;
            }
            &.left {
              border: none !important;
            }
          }
        }
      }
    }
  }
}
.ng-dropdown-panel {
  .ng-dropdown-panel-items {
    .ng-option {
      &.ng-option-selected,
      &.ng-option-selected.ng-option-marked {
        color: $white !important;
        background-color: $primary-color !important;
      }
    }
  }
}

.select2 {
  max-width: 100%;
}

.add-post {
  #cke_text-box {
    border: 1px solid $light-semi-gray;
  }

  form {
    .m-checkbox-inline {
      label {
        margin-bottom: 0;
      }
    }

    .form-group {
      margin-bottom: 30px;

      .form-control {
        border: 1px solid $light-semi-gray;
      }

      .col-form-label {
        padding-bottom: 0;
        padding-top: 0;

        .select2-container--default {
          margin-top: 10px;
          max-width: 100%;

          .selection {
            .select2-selection {
              .select2-search__field {
                padding: 2px;
              }
            }
          }

          .select2-selection--multiple {
            border: 1px solid $light-semi-gray;
            max-width: 100%;

            .select2-selection__rendered {
              li {
                margin-top: 6px !important;
              }
            }
          }
        }
      }
    }
  }

  .dropzone {
    margin-bottom: 30px;
  }
}

.select2-container--open {
  .select2-dropdown {
    z-index: 7;
  }
}

.select2-drpdwn {
  .select2-selection {
    border-radius: 0 !important;
    border-color: $light-color !important;
    height: 40px !important;
    padding: 5px;
  }

  .form-control {
    border-radius: 5px;
  }

  .form-control-primary {
    border-color: var(--theme-deafult);
    color: var(--theme-deafult);
  }

  .form-control-secondary {
    border-color: var(--theme-secondary);
    color: var(--theme-secondary);
  }

  .form-control-success {
    border-color: $success-color;
    color: $success-color;
  }

  .form-control-info {
    border-color: $info-color;
    color: $info-color;
  }

  .form-control-warning {
    border-color: $warning-color;
    color: $warning-color;
  }

  .form-control-danger {
    border-color: $danger-color;
    color: $danger-color;
  }

  .form-control-inverse {
    border-color: $dark-color;
    color: $dark-color;
  }

  .form-control-primary-fill {
    background-color: var(--theme-deafult);
    color: $white;
  }

  .form-control-secondary-fill {
    background-color: var(--theme-secondary);
    color: $white;
  }

  .form-control-success-fill {
    background-color: $success-color;
    color: $white;
  }

  .form-control-info-fill {
    background-color: $info-color;
    color: $white;
  }

  .form-control-warning-fill {
    background-color: $warning-color;
    color: $white;
  }

  .form-control-danger-fill {
    background-color: $danger-color;
    color: $white;
  }

  .form-control-inverse-fill {
    background-color: $dark-color;
    color: $white;
  }
}

//colors
$color_bon_jour_approx: #e0e0e0;
$color_cape_cod_approx: #424242;

.selection {
  .select2-selection {
    border-radius: 5px !important;

    .select2-search__field {
      margin-top: 0;
      padding: 3px;
    }
  }
}

.select2-container--classic {
  .select2-selection--multiple {
    .select2-selection__choice {
      padding: 2px 6px !important;
      margin-top: 0 !important;
      background-color: var(--theme-deafult) !important;
      border-color: darken($primary-color, 10%) !important;
      color: $white;
      margin-right: 8px !important;
    }

    .select2-selection__choice__remove {
      color: $white !important;
      float: right;
      margin-left: 0.5rem;
    }
  }

  .select2-results__option--highlighted[aria-selected] {
    background-color: $color_bon_jour_approx !important;
    color: $color_cape_cod_approx !important;
  }

  .select2-results__options .select2-results__option[aria-selected=true] {
    background-color: var(--theme-deafult) !important;
    color: $white !important;
  }
}

.select2-container--default {
  .select2-selection--single {
    .select2-selection__arrow {
      top: 7px !important;
      right: 10px !important;
    }
  }

  .select2-selection--multiple {
    .select2-selection__choice {
      padding: 2px 6px !important;
      margin-top: 0 !important;
      background-color: var(--theme-deafult) !important;
      border-color: darken($primary-color, 10%) !important;
      color: $white;
      margin-right: 8px !important;
    }

    .select2-selection__choice__remove {
      color: $white !important;
      float: right;
      margin-left: 0.5rem;
    }
  }

  .select2-results__option--highlighted[aria-selected] {
    background-color: $color_bon_jour_approx !important;
    color: $color_cape_cod_approx !important;
  }

  .select2-results__options .select2-results__option[aria-selected=true] {
    background-color: var(--theme-deafult) !important;
    color: $white !important;
  }
}

.ng-select-outline {
  @each $ng-name, $ng-color in (primary, $primary-color), (secondary, $secondary-color), (success, $success-color),
    (danger, $danger-color), (info, $info-color), (light, $light-color), (inverse, $dark-color),
    (warning, $warning-color)
  {
    &.form-control-#{$ng-name} {
      &.ng-select {
        padding: 0;
        border: none;
        .ng-select-container {
          border-color: $ng-color !important;
          border-radius: 0.25rem;
          color: $ng-color;
          .ng-value-container {
            .ng-placeholder {
              color: $ng-color;
            }
          }
        }
      }
    }
  }
}
.ng-select-bg {
  @each $ng-name, $ng-color in (primary, $primary-color), (secondary, $secondary-color), (success, $success-color),
    (danger, $danger-color), (info, $info-color), (light, $light-color), (inverse, $dark-color),
    (warning, $warning-color)
  {
    &.form-control-#{$ng-name} {
      &.ng-select {
        padding: 0;
        border: none;
        .ng-select-container {
          background-color: $ng-color;
          border-radius: 0.25rem;
          color: $white;
          .ng-value-container {
            .ng-placeholder {
              color: $white;
            }
          }
        }
        .ng-dropdown-panel {
          .ng-dropdown-panel-items {
            .ng-option {
              background-color: $ng-color;
              color: $white;
            }
          }
        }
        .ng-arrow-wrapper {
          .ng-arrow {
            border-color: $white transparent transparent;
          }
        }
        &.ng-select-opened {
          > .ng-select-container {
            .ng-arrow {
              border-color: transparent transparent $white;
            }
          }
        }
      }
    }
  }
}

.select2-drpdwn {
  .form-control {
    border-radius: 5px;
  }
}

/**=====================
     21. Select 2 CSS Ends
==========================**/

.ng-select {
  font-size: 1rem;
  .ng-clear-wrapper {
    color: #343a40;
    padding-left: 3px;
  }
  &.is-valid {
    .ng-select-container, &.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
        border-color: $success-color !important;
    }
  }
  &.is-invalid {
      .ng-select-container, &.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
          border-color: $danger-color !important;
      }
  }
  &.not-clear .ng-clear-wrapper {
    display: none;
  }
  .ng-arrow-wrapper .ng-arrow {
    border-color: #343a40 transparent transparent;
  }
  &.ng-select-focused {
    &:not(.ng-select-opened) > .ng-select-container {
      border-color: $primary-color !important;
      box-shadow: none;
    }
  }
  &.ng-select-single .ng-select-container {
    height: 38px;
  }
}