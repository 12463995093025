/**=====================
    06. Button CSS start
==========================**/
.btn {
  &.calendar {
    color: $theme-body-font-color;
  }
}
.btn-link {
  color: var(--theme-deafult);
}

@mixin btn-gradien($deg,$leftcolor,$leftcolor-per,$rightcolor,$rightcolor-per,$font-color){
  background-image: linear-gradient(to right,$leftcolor 0%,$rightcolor 51%,$leftcolor 100%);
  border:none;
  color:$font-color;
  background-size: auto 200%;
  transition: all 0.3s ease;
}
.btn-primary-gradien{
  @include btn-gradien(60,lighten($primary-color,8%),0%,darken($primary-color,8%),100%,$white);
  color: $white;
  &:focus,&:active,&.active{
      transition: 1.5s;
      background-position: right center;
      background-image: linear-gradient(to right, lighten($primary-color,8%),0%,darken($primary-color,8%),100%,$white) !important;
  }
}
.btn-secondary-gradien{
  @include btn-gradien(60,lighten($secondary-color,8%),0%,darken($secondary-color,8%),100%,$white);
 &:hover,&:focus,&:active,&.active,&.hover{
    transition: 1.5s;
    background-position: right center;
   background-image: linear-gradient(to right, lighten($secondary-color,8%),0%,darken($secondary-color,8%),100%,$white) !important;
  }
}
.btn-success-gradien{
  @include btn-gradien(60,lighten($success-color,8%),0%,darken($success-color,8%),100%,$white);
 &:hover,&:focus,&:active,&.active,&.hover{
    transition: 1.5s;
    background-position: right center;
   background-image: linear-gradient(to right, lighten($success-color,8%),0%,darken($success-color,8%),100%,$white) !important;
  }
}
.btn-danger-gradien{
  @include btn-gradien(60,lighten($danger-color,8%),0%,darken($danger-color,8%),100%,$white);
 &:hover,&:focus,&:active,&.active,&.hover{
    transition: 1.5s;
    background-position: right center;
   background-image: linear-gradient(to right, lighten($danger-color,8%),0%,darken($danger-color,8%),100%,$white) !important;
  }
}
.btn-warning-gradien{
  @include btn-gradien(60,lighten($warning-color,8%),0%,darken($warning-color,8%),100%,$white);
 &:hover,&:focus,&:active,&.active,&.hover{
    transition: 1.5s;
    background-position: right center;
   background-image: linear-gradient(to right, lighten($warning-color,8%),0%,darken($warning-color,8%),100%,$white) !important;
  }
}
.btn-info-gradien{
  @include btn-gradien(60,lighten($info-color,8%),0%,darken($info-color,8%),100%,$white);
 &:hover,&:focus,&:active,&.active,&.hover{
    transition: 1.5s;
    background-position: right center;
   background-image: linear-gradient(to right, lighten($info-color,8%),0%,darken($info-color,8%),100%,$white) !important;
  }
}
.btn-light-gradien{
  @include btn-gradien(60,lighten($light-color,8%),0%,darken($light-color,8%),100%,$white);
 &:hover,&:focus,&:active,&.active,&.hover{
    transition: 1.5s;
    background-position: right center;
   background-image: linear-gradient(to right, lighten($light-color,8%),0%,darken($light-color,8%),100%,$white) !important;
  }
}
.btn-dark-gradien{
  @include btn-gradien(60,lighten($dark-color,8%),0%,darken($dark-color,8%),100%,$white);
 &:hover,&:focus,&:active,&.active,&.hover{
    transition: 1.5s;
    background-position: right center;
   background-image: linear-gradient(to right, lighten($dark-color,8%),0%,darken($dark-color,8%),100%,$white) !important;
  }
}


@mixin btn-squre($border-radius){
  border-radius:$border-radius+px;
}
@mixin btn-pill($border-radius){
  border-radius:$border-radius+px;
}
@mixin btn-pill-first($radius){
  border-radius: $radius+px 0 0 $radius+px;
}
@mixin btn-pill-last($radius){
  border-radius: 0 $radius+px $radius+px 0;
}

.btn{
  padding: $btn-padding;
}
.btn-lg{
  font-size: $btn-lg-font-size;
}
.btn-sm{
  font-size: $btn-sm-font-size;
}
.btn-xs {
  padding: $btn-xs-padding;
  font-size: $btn-xs-font-size;
}
.large-btn{
  .btn{
    margin-right: 5px;
    margin-bottom: 15px;
  }
  margin-bottom: -20px;
}
// outline buttons//
@each $btn-name, $btn-color in (primary, $primary-color),
        (secondary, $secondary-color) ,
        (success, $success-color),
        (danger, $danger-color),
        (info, $info-color),
        (light, $light-color),
        (dark, $dark-color),
        (warning, $warning-color) {
  .btn-air-#{$btn-name}{
    box-shadow: 0 5px 10px 2px rgba(88,103,221,.19)!important;
    &:hover,&:active,&:not([disabled]):not(.disabled):active{
      background-color: darken( $btn-color, 10% );
      border-color: darken( $btn-color, 10% );
    }
  }
  .input-air-#{$btn-name}{
    box-shadow: 0 3px 5px 1px rgba($btn-color , 0.10)!important;
    &:focus{
      border-color:$btn-color;
    }
  }
}

.btn-gradient {
  color: $white !important;
  font-weight:500;
  padding: 10px 30px;
  border-radius: 5px;
  background: $gradient-primary;
  letter-spacing: 0.06rem;  
  &:hover {
    background-size: 100% !important;
  }
}

.btn-warning{
  // color: $white;
  &:hover,
  &.disabled{
    // color: $white;
  }
}

[class*='-gradien']{
  &:hover{
    background-size: 50% 100%;
    transition: all 0.3s ease;
    color: $white;
  }
}

.btn-square{
  @include btn-squre(0);
}
.btn-pill{
  @include btn-squre(60);
}
.btn-group-pill {
  .btn{
    &:first-child{
      @include btn-pill-first(60);
    }
    &:last-child{
      @include btn-pill-last(60);
    }
  }
}
.btn-group-square{
  .btn{
    &:first-child{
      @include btn-pill-first(0);
    }
    &:last-child{
      @include btn-pill-last(0);
    }
  }
}
.btn-showcase{
  .btn{
    margin-bottom: 10px;
    margin-right: 18px;
  }
  margin-bottom:-10px;
}

.btn-dropdown-showcase{
  .dropdown{
    margin-bottom: 10px;
    margin-right: 18px;
  }
  .btn-group{
    margin-bottom: 10px;
    margin-right: 18px;
  }
  margin-bottom:-10px;
}
.btn-group{
  .btn-primary {
    color: #2b2b2b;
  }
  .radio{
    label{
      &::before{
        top: 2px;
      }
      &::after{
        top: 7px;
      }
    }
  }
  .checkbox{
    label{
      margin-top: 3px;
    }
  }
}
.btn-group-showcase{
  >div{
    margin-bottom: -20px;
    label{
      margin-bottom: 0;
    }
  }
  .btn-group{
    margin-right: 20px;
    margin-bottom: 20px;
  }
  .checkbox {
    input[type="checkbox"] {
      display: none;
    }
  }
}
.tooltiptext {
  visibility: visible;
  width: 120px;
  background-color: #555;
  color: $white;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  opacity: 1;
  transition: opacity 0.3s;
  &::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
  }
}
/**=====================
     06. Button CSS end
==========================**/

.btn-group-switch {
  width: 100%;
  height: 38px;
  label {
      font-weight: normal;
  }
}
.form-group div:not(.control-group) > .btn-group-switch {
  width: 49%;
}
.form-group .btn-group-switch > .btn {
  width: 100%;
  line-height: 30px;
}
.btn-group-switch > .btn {
  overflow: hidden;
  width: 49%;
  border: 0 none;
  text-shadow: none;
  -webkit-transition: all .4s ease;
  -moz-transition: all .4s ease;
  -o-transition: all .4s ease;
  -ms-transition: all .4s ease;
  transition: all .4s ease;
}
.btn-group-switch .btn:not(.active) {
  text-indent: -200px;
  width: 20%;
  background: #c5d2e1;
  background: -webkit-linear-gradient(to bottom, #d6dfea 0%, #c5d2e1 40%, #b4c5d8 100%);
  background: -moz-linear-gradient(to bottom, #d6dfea 0%, #c5d2e1 40%, #b4c5d8 100%);
  background: -o-linear-gradient(to bottom, #d6dfea 0%, #c5d2e1 40%, #b4c5d8 100%);
  background: -ms-linear-gradient(to bottom, #d6dfea 0%, #c5d2e1 40%, #b4c5d8 100%);
  background: linear-gradient(to bottom, #d6dfea 0%, #c5d2e1 40%, #b4c5d8 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#d6dfea', endColorstr='#b4c5d8',GradientType=0 );
}
.btn-group-switch > .btn.active {
  width: 80%;display:inline-block;
}

/** OTROS BOTONES **/
.btn-filter {
  background-color: #f0f7fb;
  line-height: 25px;
  border-color: #e2f0f7;
  padding: 0px 10px;
  color: #262932;
  > .fa-filter::after {
      content: "\f107";
      margin-left: 5px;
  }
  &.active > .fa-filter::after {
      content: "\f106";
  }
  &:hover{
    color: #262932;
  }
}
.btn-default {
  background-color: #FFF;
  border: 1px solid #C1D9EE;
  color: $dark-color;
  &:hover, &:focus, &:active, &.active {
      background-color: darken(#FFF, 10%);
      border: 1px solid darken(#C1D9EE, 10%);
      color: $dark-color;
  }
}
.btn-purple{
  background: $purple-color;
  border-color:  $purple-color;
  &.medium-opacity{
      background: rgba($purple-color, 0.5);
      border-color:  rgba($purple-color, 0.1);
  }
  &:hover, &:focus, &:active, &.active {
      background: darken($purple-color, 10%);
      border-color:  darken($purple-color, 10%);
      color: #fff;
  }
}
.btn-outline-purple{
  color: $purple-color;
  border-color: $purple-color;
  &:hover, &:focus{
      color: $white;
      background: $purple-color;
      border-color: $purple-color;
  }
  &:active, &.active{
      color: $white !important;
      background: $purple-color !important;
      border-color: $purple-color !important;
  }
}
.btn-pink{
  background: $pink-color;
  border-color:  $pink-color;
  &.medium-opacity{
      background: rgba($pink-color, 0.5);
      border-color:  rgba($pink-color, 0.1);
  }
  &:hover, &:focus, &:active, &.active {
      background: darken($pink-color, 10%);
      border-color:  darken($pink-color, 10%);
      color: #fff;
  }
}
.btn-outline-pink{
  color: $pink-color;
  border-color: $pink-color;
  &:hover, &:focus{
      color: $white;
      background: $pink-color;
      border-color: $pink-color;
  }
  &:active, &.active{
      color: $white !important;
      background: $pink-color !important;
      border-color: $pink-color !important;
  }
}
.btn-yellow{
  background: $yellow-color;
  border-color:  $yellow-color;
  color: #22190b;
  &.medium-opacity{
      background: rgba($yellow-color, 0.5);
      border-color:  rgba($yellow-color, 0.1);
  }
  &:hover, &:focus, &:active, &.active {
      background: darken($yellow-color, 10%);
      border-color:  darken($yellow-color, 10%);
      color: #22190b;
  }
}
.btn-outline-yellow{
  color: $yellow-color;
  border-color: $yellow-color;
  &:hover, &:focus{
      color: #22190b;
      background: $yellow-color;
      border-color: $yellow-color;
  }
  &:active, &.active{
      color: $white !important;
      background: $yellow-color !important;
      border-color: $yellow-color !important;
  }
}
.btn-sky{
  background: $sky-color;
  border-color: $sky-color;
  &.medium-opacity{
      background: rgba($sky-color, 0.5);
      border-color:  rgba($sky-color, 0.1);
  }
  &:hover, &:focus, &:active, &.active {
      background: darken($sky-color, 10%);
      border-color:  darken($sky-color, 10%);
      color: #fff;
  }
}
.btn-outline-sky{
  color: $sky-color;
  border-color: $sky-color;
  &:hover, &:focus{
      color: $white;
      background: $sky-color;
      border-color: $sky-color;
  }
  &:active, &.active{
      color: $white !important;
      background: $sky-color !important;
      border-color: $sky-color !important;
  }
}
.btn-sky2{
background: $sky2-color;
border-color: $sky2-color;
&.medium-opacity{
    background: rgba($sky2-color, 0.5);
    border-color:  rgba($sky2-color, 0.1);
}
&:hover, &:focus, &:active, &.active {
    background: darken($sky2-color, 10%);
    border-color:  darken($sky2-color, 10%);
    color: #fff;
}
}
.btn-outline-sky2{
color: $sky2-color;
border-color: $sky2-color;
&:hover, &:focus{
    color: $white;
    background: $sky2-color;
    border-color: $sky2-color;
}
&:active, &.active{
    color: $white !important;
    background: $sky2-color !important;
    border-color: $sky2-color !important;
}
}
.btn-orange{
  background: $orange-color;
  border-color:  $orange-color;
  color: #fff;
  &.medium-opacity{
      background: rgba($orange-color, 0.5);
      border-color:  rgba($orange-color, 0.1);
  }
  &:hover, &:focus, &:active, &.active {
      background: darken($orange-color, 10%);
      border-color:  darken($orange-color, 10%);
      color: #fff;
  }
}
.btn-outline-orange{
  color: $orange-color;
  border-color: $orange-color;
  &:hover, &:focus{
      color: $white;
      background: $orange-color;
      border-color: $orange-color;
  }
  &:active, &.active{
      color: $white !important;
      background: $orange-color !important;
      border-color: $orange-color !important;
  }
}
.btn-blue{
  background: $blue-color;
  border-color:  $blue-color;
  &.medium-opacity{
      background: rgba($blue-color, 0.5);
      border-color:  rgba($blue-color, 0.1);
  }
  &:hover, &:focus, &:active, &.active {
      background: darken($blue-color, 10%);
      border-color:  darken($blue-color, 10%);
      color: #fff;
  }
}
.btn-outline-blue{
  color: $blue-color;
  border-color: $blue-color;
  &:hover, &:focus{
      color: $white;
      background: $blue-color;
      border-color: $blue-color;
  }
  &:active, &.active{
      color: $white !important;
      background: $blue-color !important;
      border-color: $blue-color !important;
  }
}
.btn-azure{
  background: $azure-color;
  border-color: $azure-color;
  &.medium-opacity{
      background: rgba($azure-color, 0.5);
      border-color:  rgba($azure-color, 0.1);
  }
  &:hover, &:focus, &:active, &.active {
      background: darken($azure-color, 10%);
      border-color:  darken($azure-color, 10%);
      color: #fff;
  }
}
.btn-outline-azure{
  color: $azure-color;
  border-color: $azure-color;
  &:hover, &:focus{
      color: $white;
      background: $azure-color;
      border-color: $azure-color;
  }
  &:active, &.active{
      color: $white !important;
      background: $azure-color !important;
      border-color: $azure-color !important;
  }
}
.btn-green{
  background: $green-color;
  border-color: $green-color;
  &.medium-opacity{
      background: rgba($green-color, 0.5);
      border-color:  rgba($green-color, 0.1);
  }
  &:hover, &:focus, &:active, &.active {
      background: darken($green-color, 10%);
      border-color:  darken($green-color, 10%);
      color: #fff;
  }
}
.btn-outline-green{
  color: $green-color;
  border-color: $green-color;
  &:hover, &:focus{
      color: $white;
      background: $green-color;
      border-color: $green-color;
  }
  &:active, &.active{
      color: $white !important;
      background: $green-color !important;
      border-color: $green-color !important;
  }
}
.btn-magenta{
  background: $magenta-color;
  border-color: $magenta-color;
  &.medium-opacity{
      background: rgba($magenta-color, 0.5);
      border-color:  rgba($magenta-color, 0.1);
  }
  &:hover, &:focus, &:active, &.active {
      background: darken($magenta-color, 10%);
      border-color:  darken($magenta-color, 10%);
      color: #fff;
  }
}
.btn-outline-magenta{
  color: $magenta-color;
  border-color: $magenta-color;
  &:hover, &:focus{
      color: $white;
      background: $magenta-color;
      border-color: $magenta-color;
  }
  &:active, &.active{
      color: $white !important;
      background: $magenta-color !important;
      border-color: $magenta-color !important;
  }
}
.btn-maroon{
  background: $maroon-color;
  border-color: $maroon-color;
  color: #fff;
  &.medium-opacity{
      background: rgba($maroon-color, 0.5);
      border-color:  rgba($maroon-color, 0.1);
  }
  &:hover, &:focus, &:active, &.active {
      background: darken($maroon-color, 10%);
      border-color:  darken($maroon-color, 10%);
      color: #fff;
  }
}
.btn-outline-maroon{
  color: $maroon-color;
  border-color: $maroon-color;
  &:hover, &:focus{
      color: $white;
      background: $maroon-color;
      border-color: $maroon-color;
  }
  &:active, &.active{
      color: $white !important;
      background: $maroon-color !important;
      border-color: $maroon-color !important;
  }
}
.btn-azul{
  background: $azul-color;
  border-color: $azul-color;
  color: #fff;
  &.medium-opacity{
      background: rgba($azul-color, 0.5);
      border-color:  rgba($azul-color, 0.1);
  }
  &:hover, &:focus, &:active, &.active {
      background: darken($azul-color, 10%);
      border-color:  darken($azul-color, 10%);
      color: #fff;
  }
}
.btn-outline-azul{
  color: $azul-color;
  border-color: $azul-color;
  &:hover, &:focus{
      color: $white;
      background: $azul-color;
      border-color: $azul-color;
  }
  &:active, &.active{
      color: $white !important;
      background: $azul-color !important;
      border-color: $azul-color !important;
  }
}
/** FIN OTROS BOTONES **/

.btn-inline-right {
  display: flex;
  justify-content: space-between;
  align-items: center;
}